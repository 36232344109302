import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';
//
import getRatio from './getRatio';
// ----------------------------------------------------------------------
const Image = forwardRef(({ ratio, disabledEffect = false, effect = 'blur', sx, ...other }, ref) => {
    const content = (_jsx(Box, { component: LazyLoadImage, wrapperClassName: "wrapper", effect: disabledEffect ? undefined : effect, placeholderSrc: disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg', sx: { width: 1, height: 1, objectFit: 'cover' }, ...other }));
    if (ratio) {
        return (_jsx(Box, { ref: ref, component: "span", sx: {
                width: 1,
                lineHeight: 1,
                display: 'block',
                overflow: 'hidden',
                position: 'relative',
                pt: getRatio(ratio),
                '& .wrapper': {
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    backgroundSize: 'cover !important',
                },
                ...sx,
            }, children: content }));
    }
    return (_jsx(Box, { ref: ref, component: "span", sx: {
            lineHeight: 1,
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            '& .wrapper': {
                width: 1,
                height: 1,
                backgroundSize: 'cover !important',
            },
            ...sx,
        }, children: content }));
});
export default Image;
